<template>
  <div class="directivesWrap">
    <div class="operations">
      <el-button
        type="primary"
        plain
        @click="$router.push('/helpDocuments/add')"
        >添加文档</el-button
      >
      <el-button
        type="danger"
        plain
        @click="batchDeleteSelected(selectedItems.map((item) => item.id))"
        >批量删除</el-button
      >
    </div>
    <div class="directivesList">
      <el-table
        ref="multipleTable"
        :data="talbeListData.result"
        style="width: 100%"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          :prop="c.key"
          :label="c.label"
          v-for="c in columns"
          :key="c.key"
        >
          <template slot-scope="scope">
            <span v-if="c.key.indexOf('time') > -1">
              {{ getTime(scope.row.create_time) }}
            </span>
            <div
              class="answer"
              v-else-if="c.key == 'answer'"
              v-html="scope.row[c.key]"
            />

            <span v-else-if="c.key == 'operations'">
              <el-button
                type="primary"
                size="mini"
                @click="toEdit(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="batchDeleteSelected([scope.row.id])"
                >删除</el-button
              >
            </span>
            <span v-else>{{ scope.row[c.key] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-if="talbeListData.total > 8"
        :handleSizeChange="handleSizeChange"
        :handleCurrentPageChange="handleCurrentPageChange"
        :total="talbeListData.total"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import moment from "moment";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      columns: [
        {
          key: "question",
          label: "问题",
        },
        {
          key: "answer",
          label: "答案",
        },
        {
          key: "create_time",
          label: "创建时间",
        },
        {
          key: "update_time",
          label: "修改时间",
        },
        {
          key: "operations",
          label: "操作",
        },
      ],
      talbeListData: {},
      selectedItems: [],
      searchValue: "",
    };
  },
  mounted() {
    this.queryHelpDocumentsList({});
  },
  methods: {
    search() {
      this.queryHelpDocumentsList({
        instruction_name: this.searchValue,
        type_id: this.selectedType,
      });
    },
    batchDeleteSelected(ids) {
      this.$request.deleteDocuments({ ids }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.queryHelpDocumentsList({});
      });
    },
    queryHelpDocumentsList({
      page_num = 1,
      page_size = 8,
      instruction_name,
      type_id,
    }) {
      this.$request
        .queryHelpDocumentsList({
          page_num,
          page_size,
          instruction_name,
          type_id,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.talbeListData = res?.data || {};
        });
    },
    getTime(t) {
      return moment(t).format("YYYY-MM-DD HH:mm");
    },
    handleSizeChange(v) {
      this.queryHelpDocumentsList({ page_size: v });
    },
    toEdit(id) {
      this.$router.push({
        path: "/helpDocuments/edit",
        query: {
          id,
        },
      });
    },
    handleCurrentPageChange(v) {
      this.queryHelpDocumentsList({ page_num: v });
    },
    handleSelectionChange(val) {
      this.selectedItems = val;
    },
  },
};
</script>

<style lang='scss' scoped>
.directivesWrap {
  background-color: #fff;
  padding-top: 16px;
  .operations {
    margin: 0 24px 10px;
  }
  .directivesList {
    padding: 10px 24px 24px;
    .answer {
      height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>